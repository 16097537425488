import { OverviewConfig } from '@salary/common/component';

export function getRueckmeldungenOverviewConfigs(): OverviewConfig[] {
  return [
    {
      caption: 'Entgeltersatzleistungen',
      iconName: 'price_change',
      description: getDescription('Entgeltersatzleistungen'),
      path: 'entgeltersatzleistungen',
    },
    {
      caption: 'Versicherungsnummernabfrage',
      iconName: 'format_list_numbered',
      description: getDescription('Versicherungsnummernabfrage'),
      path: 'versicherungsnummern',
    },
    {
      caption: 'Sofortmeldungen',
      iconName: 'forward_10',
      description: getDescription('Sofortmeldungen'),
      path: 'sofortmeldungen',
    },

    {
      caption: 'DEÜV-Rückmeldungen',
      iconName: 'connect_without_contact',
      description: getDescription('DEÜV'),
      path: 'deuevrueckmeldungen',
    },
    {
      caption: 'Aufwendungsausgleichsgesetz (AAG)',
      iconName: 'badge',
      description:
        'Mit dieser Auswahl können Sie die Rückmeldungen für das Aufwendungsausgleichsgesetz (AAG) ansehen.',
      path: 'aufwendungsausgleichsgesetz',
    },
    {
      caption: 'Arbeitsunfähigkeitsmeldung (eAU)',
      iconName: 'engineering',
      description: getDescription('Arbeitsunfähigkeitsmeldung (eAU)'),
      path: 'arbeitsunfaehigkeitsmeldungen',
    },
  ];
  function getDescription(caption: string) {
    return `Mit dieser Auswahl können Sie die Rückmeldungen für die ${caption} ansehen.`;
  }
}
